import { Controller } from "@hotwired/stimulus"
// Connects to data-controller="twilio"
export default class extends Controller {
  static targets = ["form", "errors"]

  connect() {
    this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content")
  }

  send(event) {
    event.preventDefault()

    fetch(this.formTarget.action, {
      method: "POST",
      headers: { "Accept": "application/json", "X-CSRF-Token": this.csrfToken },
      body: new FormData(this.formTarget)
    })
      .then(response => response.json())
      .then((data) => {
        if(data.errors){
          this.errorsTarget.innerText = data.errors[0];
        }else{
          this.errorsTarget.innerText = '';
          var old_tab = document.querySelector('div[data-active="true"]');
          old_tab.classList.add('hidden');
          old_tab.removeAttribute('data-active');

          // show new tab
          var new_tab = document.querySelector('div[data-tab="call"]');
          new_tab.classList.remove('hidden');
          new_tab.setAttribute('data-active', true);
        }
      });
  }
}
