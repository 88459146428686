import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = [
    "wrapper",
    "menuList",
    "menuButton",
    "anchorList",
    "anchorButton",
    "accordionList",
    "pricingButton",
    "pricingList",
    "additional"
  ]

  connect() {
    tippy('[data-tippy-content]', {
      theme: 'light-border',
    });

    const pathname = window.location.pathname;
    const hash = window.location.hash.split("#").pop().toLowerCase();

    // trigger pricing if hash matches a currency and we're on the pricing page
    if( ['gbp','usd','eur'].includes(hash) && pathname.includes('pricing') ) {
      this.triggerPricing(hash);
    }

  }

  anchorLink(event) {

    let url = new URL(event.target.href)

    if( window.location.pathname === url.pathname ) {
      this.toggleMenu(event);
    }

  }

  toggleMenu(event) {

    this.wrapperTarget.classList.toggle('menu--open');
    this.menuListTarget.classList.toggle('hidden');
    this.menuButtonTarget.querySelectorAll('svg').forEach(function(item, i){
      item.classList.toggle('hidden');
    });

  }

  openAnchor(event) {

    this.anchorListTarget.classList.toggle('hidden');

    this.anchorButtonTarget.querySelectorAll('svg').forEach(function(item, i){
      item.classList.toggle('hidden');
    });

  }

  openAccordion(event) {

    if( !event.currentTarget.classList.contains('open') ) {

      this.accordionListTarget.querySelector('.open').classList.remove('open');

      event.currentTarget.classList.add('open')

    }

  }

  openModal(event) {

    // initiated from click
    if (event.currentTarget) {

      window.history.pushState({}, '', event.currentTarget.getAttribute('href'));

      event.preventDefault();
      event.stopImmediatePropagation();

      var modal = event.currentTarget.getAttribute('data-modal');

    // initiated from hash url
    } else {

      var modal = event.querySelector('a').getAttribute('data-modal');

    }

    document.querySelector('html').classList.add('has-modal');
    document.querySelector('div[data-modal=' + modal + ']').classList.remove('hidden');
    document.querySelector('div[data-modal=' + modal + ']').classList.add('open');

  }

  closeModal(event) {

    window.history.pushState({}, '', window.location.pathname);

    event.preventDefault();
    event.stopImmediatePropagation();

    document.querySelector('html').classList.remove('has-modal');
    document.querySelector('div[data-modal].open').classList.add('hidden');
    document.querySelector('div[data-modal].open').classList.remove('open');

  }

  switchTab(event) {

    // get tab to switch to
    var tab = event.currentTarget.getAttribute('data-tab');

    // hide old tab
    var old_tab = document.querySelector('div[data-active="true"]');
    old_tab.classList.add('hidden');
    old_tab.removeAttribute('data-active');

    // show new tab
    var new_tab = document.querySelector('div[data-tab="' + tab + '"]');
    new_tab.classList.remove('hidden');
    new_tab.setAttribute('data-active', true);

  }

  triggerPricing(currency) {
    var event = document.createEvent('HTMLEvents');
    event.initEvent('click', true, false);
    document.querySelector('button[data-currency="' + currency  + '"]').dispatchEvent(event);
  }

  switchPricing(event) {

    // unset current active tab
    var element = event.target;
    element.parentNode.parentNode.querySelector('.active').classList.remove('active');

    // set new active tab
    event.currentTarget.classList.add('active');

    // get currency to switch to
    var currency = event.currentTarget.getAttribute('data-currency');

    // update URL
    window.history.pushState({}, '', '/pricing#' + currency);

    // get additional pricing
    const additionalPricing = new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currencyDisplay: 'narrowSymbol',
      currency: currency
    }).formatToParts(2.50).map(val => val.value).join('');

    this.additionalTargets.forEach(function(item, i){
        item.innerHTML = additionalPricing;
    });

    // switch all prices
    this.pricingListTarget.querySelectorAll('div[data-currency]').forEach(function(item, i){

      if( item.getAttribute('data-currency') === currency ) {
        item.classList.remove('hidden');
      } else {
        item.classList.add('hidden');
      }

    });

  }

}
