import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="cookies"
export default class extends Controller {

  static targets = [ "banner" ]

  connect() {

    let cookieConsent = this.getCookieByName( 'cookieConsent' );

    if ( cookieConsent === 'OK' || cookieConsent === 'NotOK' ) {

      if ( cookieConsent === 'NotOK') {
        this.removeCookies();
      }

      this.bannerTarget.classList.add('hidden');

    } else {

      this.bannerTarget.classList.remove('hidden');

    }

  }

  accept(event) {
    this.bannerTarget.classList.add('hidden');
    document.cookie = 'cookieConsent=OK; expires=Thu, 31 Dec 2099 23:59:59 UTC; domain=doorflow.com; path=/';
  }

  decline(event) {
    this.bannerTarget.classList.add('hidden');
    document.cookie = 'cookieConsent=NotOK; expires=Thu, 31 Dec 2099 23:59:59 UTC; domain=doorflow.com; path=/';
    this.removeCookies();
  }

  getCookieByName(cookie) {
    // With thanks to w3schools.com
    let name = cookie + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  getCookieNamesArray() {
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let endOfName = ca[i].indexOf('=');
      if( endOfName > 0 ) {
        ca[i] = ca[i].substring( 0, endOfName ).trim();
      } else {
        ca[i] = '';
      }
    }
    return ca;
  }

  removeCookies() {
    let cookieNames = this.getCookieNamesArray();
    const prefixes = ['_ga', '_gid', 'cp', 'callpage'];

    for (let i = 0; i < cookieNames.length; i++) {
      const cookieName = cookieNames[i];
      if (prefixes.some(prefix => cookieName.indexOf(prefix) === 0)) {
        document.cookie = cookieName + '=delete; expires=Sun, 1 Jan 2023 23:59:59 UTC; domain=doorflow.com; path=/;';
      }
    }

  }

}
